import React, {useEffect, useState} from "react";
import {createTheme, MuiThemeProvider} from "@material-ui/core";
import {getExpenses} from "../../api/expense";
import MaterialTable from "material-table";
import LoadingBar from "../reimbursement/loading";
import moment from "moment";
import {Auth} from "../core";
import {useHistory} from "react-router-dom";
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import {Button, Grid} from "@mui/material";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const theme = createTheme({
    palette: {
        primary: {
            main: "#1976d2",
        },
        secondary: {
            main: "#ffffff"
        }
    },
    typography: {
        fontSize: 12,
        fontFamily: "Montserrat"
    }
});

/** TODO: Added sample response as a reference as we are still building the UI
{
    "id": "exp_HnQslSeR60mMmJUO8XO/ZA==",
    "tenantID": "tnt_id_ccc",
    "userID": "3",
    "amount": {
        "amount": 50000,
            "currency": "USD"
    },
        "transactionDate": "2024-02-08T00:00:00+00:00",
        "createdAt": 1707457981190,
        "updatedAt": 1707457981190,
        "status": 4,
        "expenseType": 1,
        "description": "Test upload receipts and dynamic values",
        "title": "UI Test #1",
        "category": "RENT",
        "receipts": null,
        "reimbursement": null,
        "report": {
        "payment": {
            "type": "CHECK",
                "paymentID": "ccc:tnt_id_ccc:pinst_1",
                "paymentDate": "2024-01-31T23:59:00+00:00"
        }
    }
},
*/

export interface Money {
    amount: number,
    currency: string
}

export interface Expense {
    id: string,
    userID: string,
    title: string,
    description: string,
    amount: Money,
    transactionDate: string,
    type: number,
    createdAt: number,
    category: string,
    report: Report,
}

export interface Report {
    payment: Payment,
}

export interface Payment {
    type: string,
    paymentID: string,
    paymentDate: string,
}

interface User {
    id: number,
    role: number,
}

export default function ExpenseDashboard() {

    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [expenses, setExpenses] = useState([]);
    const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
    const [endDate, setEndDate] = useState(moment());
    const [user, setUser] = useState<User|null>(null);

    const loadUser = async () => {
        if (!user) {
            setUser(await Auth.getUser());
        }
    }

    const authorizeUser = () => {
        if (user && user.role !== 2) {
            history.push('/unauthorized');
        }
    }

    const fetchExpenses = async () => {
        if (!user) {
            return;
        }

        const response = await getExpenses(
            user.id,
            startDate.utc().format("YYYY-MM-DD"),
            endDate.utc().format("YYYY-MM-DD")
        );

        const rawExpenses = response.expenses;

        const reportOnlyExpenses = rawExpenses.filter((expense: Expense) => expense.type == 1);

        const normalizedExpenses = reportOnlyExpenses.map((expense: Expense) => (
            {
                userID: expense.userID,
                amount: `$${expense.amount.amount / 100}`,
                transactionDate: moment(expense.transactionDate).format("MM/DD/YYYY"),
                submissionDate: moment(expense.createdAt).format("MM/DD/YYYY"),
                description: expense.description,
                payee: expense.title,
                paymentMethod: formatPaymentMethod(expense.report.payment.type),
                referenceNumber: expense.report.payment.paymentID
            }
        ))
        setExpenses(normalizedExpenses);
        setIsLoading(false);
    }

    function formatPaymentMethod(input: string): string {
        return input
            .toLowerCase()
            .split('_')
            .filter(word => word.length > 0)  // Remove empty strings
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const loadPage = async () => {
        await loadUser();
        authorizeUser();
        await fetchExpenses();
   }

    useEffect(() => {
        loadPage();
    }, [user, startDate, endDate]);

    const dateRanges = {
        'Today': [moment(), moment()],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'Last 2 Months': [moment().subtract(2, 'month'), moment()],
        'Last 3 Months': [moment().subtract(3, 'month'), moment()],
        'Last 6 Months': [moment().subtract(6, 'month'), moment()]
    };

    const dateRangePickerInitialSetting = {
        ranges: dateRanges,
        alwaysShowCalendars: true,
        showCustomRangeLabel: true,
        showDropdowns: true,
        startDate: startDate,
        endDate: endDate
    }

    const handleDateRangePickerApply = async(event: any, picker: any) => {
        setStartDate(picker.startDate);
        setEndDate(picker.endDate);
        setIsLoading(true);
    }

    return (
        <MuiThemeProvider theme={theme}>
            {isLoading ?
                <div
                    className="loadingBar"
                    style={{display: isLoading ? "flex" : "none"}}
                >
                    <LoadingBar inProgress={isLoading}/>
                </div>
                :
                <div>
                    <div style={{padding: 24}}>
                        <DateRangePicker
                            initialSettings={dateRangePickerInitialSetting}
                            onApply={handleDateRangePickerApply}
                        >
                            <ButtonGroup variant="contained" color="secondary" aria-label="split button">
                                <Button> {startDate.format("MM/DD/YYYY")} - {endDate.format("MM/DD/YYYY")} </Button>
                                <Button
                                    size="small"
                                    aria-haspopup="true"
                                >
                                    <i className="fa fa-calendar" aria-hidden="true"> </i>
                                    <ArrowDropDownIcon />
                                </Button>
                            </ButtonGroup>
                        </DateRangePicker>
                    </div>
                    <div style={{padding: 24}}>
                        <MaterialTable
                            options={{
                                exportButton: true
                            }}
                            columns={[
                                {
                                    title: "Payee",
                                    field: "payee"
                                },
                                {
                                    title: "Description",
                                    field: "description"
                                },
                                {
                                    title: "Amount",
                                    field: "amount"
                                },
                                {
                                    title: "Expense Date",
                                    field: "transactionDate"
                                },
                                {
                                    title: "Submission Date",
                                    field: "submissionDate"
                                },
                                {
                                    title: "Payment Method",
                                    field: "paymentMethod"
                                },
                                {
                                    title: "Reference Number",
                                    field: "referenceNumber"
                                },
                                {
                                    title: "Submitted By",
                                    field: "userID"
                                }
                            ]}
                            data={expenses}
                            title="Expenses"
                        />
                    </div>
                </div>
            }
        </MuiThemeProvider>
    )
}